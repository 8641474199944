import { Helmet } from 'react-helmet';

export default function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Scovoicu Bacau</title>
        <meta name="description" content="Scoala Gimnaziala Octavian Voicu Bacau" />
        {/* Alte metadate */}
      </Helmet>


      <div className="bg-white rounded-lg mb-6 w-auto animate-fade-in">
  <div className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg flex items-center justify-between">
    <div>
      <p className="text-lg font-semibold">Rezultate finale - Concurs pentru ocuparea postului de Secretar III</p>
    </div>
    <a
      href="/documente/secretar-concurs-rezultate-finale-3704-18-12-2024"
      className="bg-white text-blue-500 font-semibold py-1 px-3 text-sm rounded hover:bg-gray-200 transition"
    >
      Vezi detalii
    </a>
  </div>
</div>

<div className="bg-white rounded-lg w-auto animate-fade-in delay-200">
  <div className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg flex items-center justify-between">
    <div>
      <p className="text-lg font-semibold">Anunt concurs - Administrator de patrimoniu</p>
    </div>
    <a
      href="/documente/administrator-de-patrimoniu-anunt-concurs-3750-19-12-2024"
      className="bg-white text-blue-500 font-semibold py-1 px-3 text-sm rounded hover:bg-gray-200 transition"
    >
      Vezi detalii
    </a>
  </div>
</div>

<div className="bg-white rounded-lg w-auto animate-fade-in delay-200">
  <div className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg flex items-center justify-between">
    <div>
      <p className="text-lg font-semibold">Rezultate finale - Administrator de patrimoniu</p>
    </div>
    <a
      href="/documente/administrator-patrimoniu-rezultate-finale-22-01-2025"
      className="bg-white text-blue-500 font-semibold py-1 px-3 text-sm rounded hover:bg-gray-200 transition"
    >
      Vezi detalii
    </a>
  </div>
</div>

      <div className='bg-white rounded-lg m-11 w-auto'>
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          <span class="text-blue-600 font-bold">Bine ați venit</span> la
          <br />
          <span class="text-blue-600 font-bold">Școala Gimnazială Octavian Voicu</span> din Bacău!
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">
          Situată la periferia orașului Bacău, <span class="font-bold">Școala Gimnazială Octavian Voicu</span> este locul unde educația de calitate și dezvoltarea armonioasă a copiilor se află în prim-plan. Cu o tradiție îndelungată în învățământ, școala noastră oferă un mediu sigur, prietenos și stimulativ pentru elevi, unde aceștia sunt încurajați să își descopere pasiunile, să își dezvolte abilitățile și să atingă potențialul maxim.
          Misiunea noastră este să formăm viitorii cetățeni ai comunității, pregătindu-i atât academic, cât și din punct de vedere al valorilor umane. Profesorii noștri dedicați aplică metode moderne de predare, adaptate nevoilor fiecărui elev, într-o atmosferă plină de încredere și respect reciproc.
          <br />
          Ne mândrim cu rezultatele elevilor noștri, fie că este vorba despre performanțe la examene, concursuri școlare sau implicare în activități extracurriculare. Printr-o abordare centrată pe elev, ne dorim să cultivăm curiozitatea intelectuală, creativitatea și spiritul de colaborare.
          <br />
          Vă invităm să descoperiți mai multe despre noi și să faceți parte din comunitatea noastră școlară!
        </p>
      </div>

      <div className="mt-4 md:flex items-center">

        <div className="bg-indigo-400 rounded-lg m-10 w-auto md:w-1/2">
          <p className="mt-8 ml-8 mr-8 text-white text-base text-3xl font-bold text-center">
            Deviza
          </p>
          <p className="mb-8 ml-8 mr-8 mb-8 text-white text-base md:text-sm text-center text-2xl">Numai împreună învățând reușim să ne pregătim pentru o societate optimă! Copiii de azi clădesc statul de mâine, ei învață și aleg conștient!</p>
        </div>
        <div className="bg-indigo-400 rounded-lg m-10 w-auto md:w-1/2">
          <p className="mt-8 ml-8 mr-8 text-white text-base text-3xl font-bold text-center">
            Misiunea
          </p>
          <p className="mb-8 ml-8 mr-8 mb-8 text-white text-base md:text-sm text-center text-2xl">Se oferă educație la standard de calitate prin centrarea învățării pe elev, pentru desăvârșirea intelectuală, morală și profesională a elevilor, în vederea adaptării la schimbarea continuă a societății, condiție esențială a progresului economic și cultural.</p>
        </div>
      </div>

      <div className="bg-white rounded-lg m-11 w-auto">
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          Ținte strategice
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">1. Formarea cadrelor didactice în vederea asigurării creșterii calității în educație pentru toate domeniile și toți indicatorii din standardele de evaloare periodică a unităților de învățământ preuniversitar</p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">2. Promovarea imaginii școlii în contextul climatului concurențial actual de descentralizare și autonomie instituțională</p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">3. Reconsiderarea managementului la nivelul școlii și al clasei în vederea egalizării șanselor la educație pentru toți elevii în vederea eradicării pericolului de abandon școlar</p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">4. Păstrarea și modernizarea bazei tehnico-materiale și generalizarea accesului la informația electronică</p>
      </div>

      <div className='bg-white rounded-lg m-11 w-auto'>
      <div class="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg flex items-center justify-between">
  <div>
        <p class="text-lg">
	  Tichete sociale pentru achiziția de produse alimentare,<br/>
	  prin Programul 'Sprijin pentru România'
	</p>
  </div>
  <a 
    href="https://mfe.gov.ro/programe/autoritati-de-management/autoritatea-de-management-pentru-programul-operational-ajutorarea-persoanelor-defavorizate/tichete-sociale-pentru-achizitia-de-produse-alimentare-prin-programul-sprijin-pentru-romania/"
    class="bg-white text-blue-500 font-semibold py-1 px-2 text-sm 
    rounded hover:bg-gray-200 transition"
  >
    Vezi detalii
  </a>
</div>
</div>

      <br /><br /><br /><br />
    </>
  );
}
